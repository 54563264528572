import React, { FC } from 'react';
import { ThemeProps } from '../theme';
import SymantiqLogo from '../atoms/SymantiqLogo';
import { Hint, InlineLink } from '../atoms/typography';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const Footer: FC<ThemeProps> = ({ feedbackTheme }) => {
    return <Container>
        <SymantiqLogo feedbackTheme={feedbackTheme} />
        <Hint center feedbackTheme={feedbackTheme}>By continuing, you agree to our Privacy Policy outlined in the <InlineLink feedbackTheme={feedbackTheme} href="https://docs.google.com/document/d/12URb9aZTsPpieSMd1kF736_nT4UwoPisCFe_j_t7rNM/edit?usp=sharing" target="_blank">Privacy Notice</InlineLink></Hint>
    </Container>
}

export default Footer;
